.share-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-around;
    align-items: center;
}

.share-section {
    padding: 1.5rem;
}

.create-link * {
    transition: all .3s ease-out;
}

.center{
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;
}

.cipher-container {
    min-width: 60vw;
    max-width: 60vw;
}