@charset "utf-8";

// Import a Google Font
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap&family=Roboto+Mono:wght@400;500;700');
// Set your brand colors
$purple: #8a4d76;
$pink: #fa7c91;
$brown: #757763;
$beige-light: #d0d1cd;
$beige-lighter: #eff0eb;
$cyber-gold: #ffb800;

// Update Bulma's global variables
$family-sans-serif: 'Roboto', sans-serif;
$family-secondary: 'Roboto Mono', 'Roboto';
$grey-dark: $brown;
$grey-light: $beige-light;
//$primary: $purple;
$link: $pink;
$widescreen-enabled: false;
$fullhd-enabled: false;

// Update some of Bulma's component variables
//$body-background-color: $beige-lighter;
$control-border-width: 2px;
$input-border-color: transparent;
$input-shadow: none;
$button-static-color: hsl(0, 0%, 0%) !default;
$button-static-background-color: $cyber-gold !default;
$button-static-border-color: #fff!default;
$button-background-color: $cyber-gold;
$button-focus-border-color: #678b99;
//$button-border-color: #fff
$section-padding: 6rem 1.5rem;

// Import only what you need from Bulma
@import '../node_modules/bulma/sass/utilities/_all.sass';
@import '../node_modules/bulma/sass/helpers/_all.sass';
@import '../node_modules/bulma/sass/elements/button.sass';
@import '../node_modules/bulma/sass/elements/container.sass';
@import '../node_modules/bulma/sass/elements/form.sass';
@import '../node_modules/bulma/sass/elements/title.sass';
@import '../node_modules/bulma/sass/components/navbar.sass';
@import '../node_modules/bulma/sass/layout/hero.sass';
@import '../node_modules/bulma/sass/layout/section.sass';
