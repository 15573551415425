.typed-cursor{
    opacity: 1;
    animation: typedjsBlink 0.7s infinite;
    -webkit-animation: typedjsBlink 0.7s infinite;
    animation: typedjsBlink 0.7s infinite;
}
@keyframes typedjsBlink{
    50% { opacity: 0.0; }
}
@-webkit-keyframes typedjsBlink{
    0% { opacity: 1; }
    50% { opacity: 0.0; }
    100% { opacity: 1; }
}
.typed-fade-out{
    opacity: 0;
    transition: opacity .25s;
    -webkit-animation: 0;
    animation: 0;
}

.congrats_emoji{
  width: 50px;
  height: auto;
  max-width: 10rem;
  height: auto;
}

span.congrats_emoji {
  font-size: 40px;
}

/* Blackbox.js */
.blackbox_container {
  position: relative;
  white-space: pre-wrap;
  height: 90vh;
  width: 100vw;
}

.center-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.text_narrow {
  padding: 0em 1.5em;
}

.text_narrow_with_img {
  margin: 1.5em;
}

.offset-responsive {
  top: 7vh;
  left: 7%;
}

@media only screen and (min-width: 768px) {
  .text_narrow {
    width: 35%;
    padding: 0 0;
  }

  .text_narrow_with_img {
    position: absolute;
    padding: 0 0;
  }

  .text_narrow_big_paragraph {
    top: 53vh;
    left: 47%;
  }

  .blackbox_container_with_img {
    position: relative;
    white-space: pre-wrap;
    height: 90vh;
    width: 100vw;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1408px) {
  .text_narrow_big_paragraph {
    width: 40%
  }
}

@media only screen and (min-width: 1408px) {
  .text_narrow_big_paragraph {
    width: 35%
  }

  .offset-responsive {
    top: 15vh;
    left: 20%;
  }
}

.text_wide{
  position: absolute;
  width: 80%;
}

/* Title.js */
.position-text {
  top: 25%;
}

.title-color {
  color: #ffb800;
}

.title-size {
  font-size: 2.25rem;
}

.underline {
  border-bottom: 5px solid #ffb800;
}

.typing-animation {
  overflow: hidden;
  white-space: nowrap;
  margin: 0 auto;
}

.typing-title {
  border-right: 4px solid #ffb800;
  height: 45px;
  animation: 
    typing-title 2s steps(24, end),
    cursor-blink 1s step-end 2 forwards;
}

.typing-subtitle {
  opacity: 0;
  border-right: 3px solid #ffb800;
  height: 25px;
  animation:
    typing-subtitle 8s steps(30, end) 2.5s infinite forwards,
    cursor-blink 1s step-end 2.5s infinite;
}

@keyframes typing-title {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

@keyframes typing-subtitle {
  0% {
    width: 0%; opacity: 1;
  }
  25% {
    width: 100%;
  }
  43.75% {
    width: 0%;
  }
  68.75% {
    width: 100%;
  }
  81.25% {
    width: 100%;
  }
  100% {
    width: 0; opacity: 1;
  }
}

@keyframes cursor-blink {
  from, to {
    border-color: transparent;
  }
  50% {
    border-color: #ffb800;
  }
}