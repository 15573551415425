.center {
    margin: auto;
}

.round {
    border-radius: 50%;
}

.cursor {
    cursor: grab;
}

.caesar-buttons {
    display: flex;
    justify-content: space-around;
}