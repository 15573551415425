.message-button {
    height: 5em;
    width: 15em;
    margin-top: 4.5rem;
}

.overlay {
    position: absolute;
}

.img-size {
    max-width: 220px;
    height: auto;
}