.App {
  text-align: center;
}

text {
  color: white;
}

.App > :nth-child(2n) {
  background-color: #FDF6E3;
}

.App > :nth-child(2n+1) {
  background-color:white;
}