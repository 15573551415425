@media only screen and (min-width: 950px) {
    .position-img {
        position: relative;
        bottom: 0.5em;
        left: 8.5em; 
    }
    .position-letter-encodings {
        position: absolute;
        bottom: 1em;
    }
}

.button-height {
    height: 2em;
}

.center-content {
    justify-content: center; 
    align-items: center;
}