body{
    font-family: 'Roboto Mono', Avenir;
    font-weight: bold;
}

.title_img{
    background-image: url("/cryptography.jpg");
}

.title_design{
    font-size: 5rem;
    padding-top: 100px;
}

.desc{
    padding-top: 60px;
    padding-bottom: 150px;
    width: 60%;
}

.content-custom{
    color: cipher-gold;
    font-weight: bold;
    text-align: center;
    position: relative;
    padding: 50px;
}

.row_space{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 30px;
    align-items: center;
}

.img{
    border-radius: 30px;
}

.small_font{
    font-size: 1.3rem;
}

.mid_font{
    font-size: 1.5rem;
}

.large_font{
    font-size: 2rem;
}

.button-hover-border:hover{
    border-color: lightgrey;
    border-width: 0.3rem;
}

@media (max-width: 850px) {
    .title_design{
        font-size: 4.875rem;
    }
    .desc{
        font-size: 2rem;
    }

    .is-size-4{
        font-size: 1.125rem;
    }

    .is-size-3{
        font-size: 1.5rem;
    }
}
  
